import { TrackProxyInput } from 'custom';

export default class ClientActions {
    private readonly trackEndpointUrl: string;

    private readonly hash: string;

    constructor(trackEndpointUrl: string, hash: string) {
        this.trackEndpointUrl = trackEndpointUrl;
        this.hash = hash;
    }

    public track(result: string): void {
        window
            .fetch(this.trackEndpointUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    result,
                } as TrackProxyInput),
            })
            .catch(console.error);
    }

    public refreshFinnSession(): void {
        fetch(`/auth/refresh?hash=${this.hash}`, {
            credentials: 'include',
        }).catch(console.error);
    }
}
