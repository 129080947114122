import { Identity } from '@m10s/account-sdk-browser/identity';
import { decode } from '@finn-no/safe-props';
import ClientActions from './client-actions';
import SchibstedAccountSessionHandler from './schibsted-account-session-handler';
import { IdentityProps } from 'custom';

export type DataProps = {
    identity: IdentityProps;
    hash: string;
    trackEndpointUrl: string;
};

window.addEventListener('load', () => {
    const div = document.getElementById('login-frontend-login-refresh-podlet');

    if (div?.dataset.props) {
        const dataProps: DataProps = decode(div.dataset.props || '');

        const clientActions: ClientActions = new ClientActions(
            dataProps.trackEndpointUrl,
            dataProps.hash,
        );
        clientActions.refreshFinnSession();

        const handler = new SchibstedAccountSessionHandler(
            // For all properties, see: http://techdocs.spid.no/sdks/javascript/.
            new Identity(dataProps.identity),
            clientActions,
        );
        handler.refreshSpidSession();
    }
});
